import React from 'react'

import './css/about.css'

import {
    Link
} from 'react-router-dom'

function About() {
    return (
        <div className="about-mobile">
            <div className="title">
                About the Event
            </div>

            <div className="text">
                <p>Amidst the ongoing COVID-19 pandemic, it all seems uncertain and hope seems lost. However, some people and businesses are still winning and making progress despite the looming recession, and ongoing depression. Since success is empirical and not accidental, it makes common sense to learn these principles of success in career, business building and emotional well-being. Join our carefully selected, renowned and well-accomplished speakers who will share their stories with you. We promise that these sessions will be very practical and relatable. If you have questions ahead of time, send to <a style={{color: `#E4572E`, textDecoration:`none`}} href="mailto:ripple@r-impact.com">ripple@r-impact.com</a></p>
            </div>

            <div className="button">
                <button>
                    <Link className="link" to='/form'>
                        Register For The Event
                    </Link>
                </button>
            </div>
        </div>
    )
}

export default About
