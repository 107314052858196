import React from 'react'

import PeopleInfo from './PeopleInfo'

import onyedikachiEkwerike from '../../images/onyedikachi-ekwerike.jpeg'
import ukineboDare from '../../images/ukinebo-dare.jpg'
import jamesYeku from '../../images/person-two.png'

import './css/hosts.css'

const People = props => {
    return (
        <div className="hosts-mobile">
            <div className="title">{props.title}</div>

            <div className="content">
                <PeopleInfo
                    image={onyedikachiEkwerike}
                    name="Onyedikachi Ekwerike"
                    position="Founder, Postpartum Support Network (PSN) Africa"
                    title="RE-START!"
                    time="03:30 PM"
                    date="May 19th, 2020"
                />
                <PeopleInfo
                    image={jamesYeku}
                    name="James Yeku"
                    position="Ass. Professor, African Digital Humanities, University of Kansas"
                    title="From first degree in Nigeria to second degree in Canada or USA"
                    time="03:30 PM"
                    date="May 15th, 2020"
                />
                <PeopleInfo
                    image={ukineboDare}
                    name="Ukinebo Dare"
                    position="Founder, Postpartum Support Network (PSN) Africa"
                    title="RE-START!"
                    time="03:30 PM"
                    date="May 19th, 2020"
                />
            </div>
        </div>
    )
}

export default People
