import React from 'react'

import './css/benefits.css'

import backgroundHead from '../../images/background.jpg'

function Benefits() {
    return (
        <div className="benefits-mobile">
            <div className="background"></div>

            <div className="content">
                <div 
                    className="image"
                    style={{
                        backgroundImage: `url(${backgroundHead})`,
                        backgroundSize:`cover`,
                        backgroundPosition:`center`
                    }}
                ></div>

                <div className="text">
                    <div className="border-top"></div>
                    <div className="title">Benefit of attending the Event</div>
                    {/* <div className="normal">
                        Lorem Khaled Ipsum is a major key to success. Learning is cool, but knowing is better.
                    </div> */}

                    <div className="sub-title">
                        For Careers:
                    </div>

                    <ul>
                        <li>Free CV evaluation for participants</li>
                        <li>Free personalized career coaching workshops</li>
                    </ul>

                    <div className="sub-title">
                        For Businesses:
                    </div>

                    <ul>
                        <li>Access to free business support</li>
                        <li>Access to recruitment services</li>
                    </ul>

                    <div className="normal">
                        All registered participants will receive a 5-minute read summary of the conference they attended.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Benefits
