import React from 'react'
import time from '../../icons/time.svg'
import date from '../../icons/date.svg'

import './css/people-info.css'

const PeopleInfo = props => {
    return (
        <div className="people-info-desktop">
            <div 
                className="container"
                style={{
                    backgroundImage: `url(${props.image})`,
                    backgroundSize:`cover`,
                    backgroundPosition:`center`
                }}
            >
                <div className="text">
                    <div className="initial">
                        <div className="name">{props.name}</div>
                        <div className="position">{props.position}</div>
                    </div>
                    <div className="more">
                        <div className="tab">
                            <b>TITLE: </b>
                            <br />
                            <span style={{marginLeft: `0vw`}}>{props.title}</span>
                        </div>
                        <br />
                        <div style={{marginBottom: `1vh`}} className="tab">
                            <img src={date} alt="date" className="icon" />
                            <span>{props.date}</span>
                        </div>
                        <div className="tab">
                            <img src={time} alt="time" className="icon" />
                            <span>{props.time}</span>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    )
}

export default PeopleInfo