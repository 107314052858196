import React from 'react'

import './css/head.css'

import backgroundHead from '../../images/background.jpg'

import {
    Link
} from 'react-router-dom'

function Head() {
    return (
        <div 
            className="head-mobile" 
            style={{
                backgroundImage: `url(${backgroundHead})`,
                backgroundSize:`cover`,
                backgroundPosition:`center`
            }}
        >
            <div className="background"></div>

            <div className="navigation">
                <div className="logo">EAZIHIRE</div>
                <div className="register">
                    <button>
                        <Link className="link" to='/form'>
                            Register
                        </Link>
                    </button>
                </div>
            </div>
            
            <div className="content">
                <div className="title">
                    <span>Theme: </span>Fostering Hope Through Opportunities
                </div>
                <div className="text">
                    ... for careers and businesses
                    <ul>
                        <li>bouncing back</li>
                        <li>taking advantage of these times</li>
                    </ul>
                </div>
            </div>

            <div className="event">
                <div className="tabs">
                    <div className="title">Date:</div>
                    May 15th 2020 - May 28th 2020
                </div>
                <div className="tabs">
                    <div className="title">Location:</div>
                    Zoom Conference Call
                </div>
            </div>
        </div>
    )
}

export default Head
