import React from 'react';

import FormMobile from './pages/mobile/Form'
import FormDesktop from './pages/desktop/Form'
import FormTablet from './pages/tablet/Form'

import './App.css'
import './responsiveness.css'

function App() {
  return (
    <div className="App">
      <div className="desktop">
          <FormDesktop />
      </div>

      <div className="tablet">
          <FormTablet />
      </div>

      <div className="mobile">
          <FormMobile />
      </div>
    </div>
  );
}

export default App;
