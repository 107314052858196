import React from 'react'

import './css/app.css'

import Head from './Head'
import About from './About'
import Benefits from './Benefits'
import Hosts from './Hosts'
// import Facilitators from './Facilitators'
import Footer from './Footer'

function Account() {
    return (
        <div className="app-tablet">
            <Head />
            <About />
            <Benefits />
            <Hosts title="Hosts" />
            {/* <Facilitators title="Facilitators" /> */}
            <Footer />
        </div>
    )
}

export default Account
