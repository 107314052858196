import React from 'react';

import Home from './Home'
import Form from './Form'

import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'

function App() {
  return (
    <div>
      <Router>
        <Route exact path='/' component={Home} />
        <Route exact path='/form' component={Form} />
      </Router>
    </div>
  );
}

export default App;
