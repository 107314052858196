import React from 'react'

import './css/footer.css'

function Footer() {
    return (
        <div className="footer-mobile">
            Copyright &copy; 2020 EaziHire. All Rights Reserved
        </div>
    )
}

export default Footer
